<!--
 * @Description: 协同单详情
 * @Author: ChenXueLin
 * @Date: 2021-08-31 10:22:38
 * @LastEditTime: 2022-10-13 10:18:57
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'任务单状态:' + detailInfo.taskStatusName"
          >
          </detail-title-content>
          <!-- 基本信息 -->
          <!-- 基本信息 -->
          <template>
            <div class="baseInfo-content-wrap">
              <div class="detailInfo-content">
                <!-- 基本信息 start -->
                <div class="baseInfo-box basicForm">
                  <div class="edit-title">基本信息</div>
                  <el-form
                    label-width="100px"
                    label-position="right"
                    class="mbForm"
                  >
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="协同单号">
                          {{ detailInfo.taskNumber }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="协同单类型">
                          {{ detailInfo.workTypeName }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item label="协同单标题">
                          {{ detailInfo.title }}
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <el-form-item label="创建人">
                          {{ detailInfo.createBy }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="创建时间">
                          {{ detailInfo.createdTime }}
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <!-- 基本信息 end -->
                <!-- 协同信息 start -->
                <div class="team-work-detail">
                  <div class="edit-title">协同信息</div>
                  <div class="team-detail-content">
                    <ul>
                      <el-form label-width="90px">
                        <el-row>
                          <li
                            v-for="(item, index) in processList"
                            :key="index"
                            :class="
                              index + 1 == processList.length
                                ? 'hiddenLine'
                                : ''
                            "
                          >
                            <div class="sort">{{ index + 1 }}</div>
                            <div class="detail">
                              <!-- 基本内容start -->
                              <template>
                                <el-col :span="6">
                                  <el-form-item label="当前操作人">
                                    {{ item.operaEmployeeName }}
                                  </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                  <el-form-item label="操作时间">
                                    {{ item.operaTime }}
                                  </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                  <el-form-item label="处理时效">
                                    {{ item.handlePrescriptionStr }}
                                  </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                  <el-form-item label="催单次数">
                                    {{ item.reminderCount }}
                                  </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="index == 0">
                                  <div
                                    style="margin-bottom:18px;"
                                    v-if="operation"
                                  >
                                    <el-button
                                      type="primary"
                                      @click="handleOpen('deal')"
                                      v-if="
                                        detailInfo.taskStatus == 1 ||
                                          detailInfo.taskStatus == 2
                                      "
                                    >
                                      处理工单
                                    </el-button>

                                    <el-button
                                      type="primary"
                                      @click="handleOpen('assign')"
                                      v-if="
                                        detailInfo.taskStatus == 1 ||
                                          detailInfo.taskStatus == 2
                                      "
                                    >
                                      指派给
                                    </el-button>
                                    <el-button
                                      type="primary"
                                      @click="hanldeTip('finish')"
                                      v-if="
                                        detailInfo.taskStatus == 1 ||
                                          detailInfo.taskStatus == 2
                                      "
                                    >
                                      工单完结
                                    </el-button>
                                  </div>
                                  <div style="margin-bottom:18px;" v-else>
                                    <el-button
                                      type="primary"
                                      @click="hanldeTip('reminder')"
                                      v-if="
                                        detailInfo.taskStatus == 1 ||
                                          detailInfo.taskStatus == 2
                                      "
                                      >催单</el-button
                                    >
                                  </div>
                                </el-col>
                                <el-col :span="24">
                                  <el-form-item label="内容">
                                    {{ item.content }}
                                  </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                  <el-form-item label="附件">
                                    <el-button
                                      style="display:block"
                                      type="text"
                                      v-for="file in item.files"
                                      :key="file.fileId"
                                      @click="dowmloadFile(file.fileId)"
                                    >
                                      {{ file.fileName }}
                                    </el-button>
                                  </el-form-item>
                                </el-col>
                              </template>
                              <!-- 基本内容start -->
                              <!-- 关联单据  start-->
                              <el-col
                                :span="24"
                                v-if="index + 1 == processList.length"
                              >
                                <el-form-item label="关联单据">
                                  <div class="bills-table">
                                    <div class="bills-table-title">
                                      <el-form
                                        label-width="80px"
                                        label-position="right"
                                      >
                                        <el-row>
                                          <el-col :span="8">
                                            <el-form-item label=" 单据号">
                                              {{
                                                taskBaseInfoRespVO.taskNumber
                                              }}
                                            </el-form-item>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form-item label="单据类型">
                                              {{
                                                taskBaseInfoRespVO.taskTypeName
                                              }}
                                            </el-form-item>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form-item label="单据状态">
                                              <span class="status">
                                                {{
                                                  taskBaseInfoRespVO.taskStatusName
                                                }}
                                              </span>
                                            </el-form-item>
                                          </el-col>
                                        </el-row>
                                      </el-form>
                                      <i
                                        class="e6-icon-arrow-b_line"
                                        v-if="!isFold"
                                        @click="handleFold"
                                      ></i>
                                      <i
                                        v-if="isFold"
                                        class="e6-icon-arrow-t_line"
                                        @click="handleFold"
                                      ></i>
                                    </div>
                                    <div class="customer-info">
                                      <el-form
                                        label-width="80px"
                                        label-position="right"
                                      >
                                        <el-row>
                                          <el-col :span="8">
                                            <el-form-item label="客户名称">
                                              {{ taskBaseInfoRespVO.corpName }}
                                            </el-form-item>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form-item label="联系人">
                                              {{
                                                taskBaseInfoRespVO.contactName
                                              }}
                                            </el-form-item>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form-item label="联系电话">
                                              {{
                                                taskBaseInfoRespVO.contactPhone
                                              }}
                                            </el-form-item>
                                          </el-col>
                                        </el-row>
                                      </el-form>
                                    </div>
                                    <el-table
                                      v-if="isFold"
                                      border
                                      style="width:800px;margin-left:0px;"
                                      height="150px"
                                      :data="taskBaseInfoDetailRespVOS"
                                    >
                                      <el-table-column
                                        show-overflow-tooltip
                                        v-for="(column,
                                        index) in installDataColumn"
                                        :key="index"
                                        :prop="column.fieldName"
                                        :label="column.fieldLabel"
                                        :min-width="column.width"
                                        :fixed="column.fixed"
                                        :align="column.align"
                                        header-align="center"
                                      >
                                      </el-table-column>
                                    </el-table>
                                  </div>
                                </el-form-item>
                              </el-col>
                              <!-- 关联单据  end-->
                            </div>
                          </li>
                        </el-row>
                      </el-form>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="record-box">
                <div class="edit-title">
                  历史记录
                </div>
                <div class="record-item-box">
                  <record-item
                    v-for="item in recordsList"
                    :key="item"
                  ></record-item>
                </div>
              </div> -->
            </div>
          </template>
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 指派弹框 start -->
    <el-dialog
      v-dialogDrag
      title="指派"
      :visible="assignVisible"
      width="500px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="() => handleClose('assgin')"
      custom-class="add-user-dialog"
    >
      <el-form
        label-width="90px"
        ref="assignForm"
        :model="assignForm"
        :rules="assignFormRules"
      >
        <el-form-item label="指派给" prop="employeeId">
          <all-user-search
            v-model="assignForm.employeeId"
            clear
            placeholder="请选择"
            title="请选择"
            :propsConfig="{
              id: 'userId',
              label: 'employeeName'
            }"
          >
          </all-user-search>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose('assign')"
          >取消</el-button
        >
        <el-button type="primary" @click="handleSubmit('assign')"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 处理工单弹框 -->
    <el-dialog
      v-dialogDrag
      title="处理工单"
      :visible="dealVisible"
      width="600px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="() => handleClose('deal')"
      custom-class="add-user-dialog"
    >
      <el-form
        label-width="60px"
        ref="dealTaskForm"
        :model="dealTaskForm"
        :rules="dealTaskFormRules"
      >
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="dealTaskForm.content"></el-input>
        </el-form-item>
        <el-form-item label="附件" class="contract-file" prop="fileIds">
          <uploadOBS
            v-model="dealTaskForm.fileIds"
            class="upload-demo"
            :fileType="10"
            :limitSize="10"
            buttonText="点击上传"
            :uploadFileList="fileList"
          >
          </uploadOBS>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose('deal')">取消</el-button>
        <el-button type="primary" @click="handleSubmit('deal')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import recordItem from "@/components/workOrderManage/recordItem.vue";
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";
import allUserSearch from "@/components/allUserSearch";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  teamTaskDetail,
  getTeamProcess,
  reminders,
  assignTask,
  handleTask,
  finishTask,
  downFile
} from "@/api";
import { exportExcelByUrl } from "@/utils/download";
export default {
  name: "teamWorkDetail",
  data() {
    return {
      synergyTaskId: "", //协同单ID
      detailInfo: {
        taskNumber: "", //协同单号
        taskStatus: "", //工单状态
        tasktype: "", //工单类型
        title: "", //工单标题
        createdTime: "", //创建事件
        createBy: "" //创建人
      },
      processList: [], //流程信息
      operation: true, //当前是否可操作
      taskBaseInfoRespVO: {}, //关联单据数组
      installDataColumn: [
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "工单ID",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "任务项状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTerminal",
          display: true,
          fieldLabel: "场景名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "terminalScene",
          display: true,
          fieldLabel: "场景类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactName",
          display: true,
          fieldLabel: "联系人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productName",
          display: true,
          fieldLabel: "商品名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "categories",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "deviceId",
          display: true,
          fieldLabel: "设备编号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "simCardNumber",
          display: true,
          fieldLabel: "SIM卡号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "centerId",
          display: true,
          fieldLabel: "中心识别码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "questionType",
          display: true,
          fieldLabel: "问题类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "problemDescription",
          display: true,
          fieldLabel: "问题描述",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "diagnoseTheProblem",
          display: true,
          fieldLabel: "诊断问题",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "treatmentPlan",
          display: true,
          fieldLabel: "处理方案",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operaEmployeeName",
          display: true,
          fieldLabel: "操作人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operaTime",
          display: true,
          fieldLabel: "操作时间",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //单据表头
      taskBaseInfoDetailRespVOS: [
        {
          taskNumber: "IOT/视频录音"
        },
        {
          taskNumber: "IOT/视频录音"
        }
      ], //单据详情数据
      isFold: false, //是否展示单据详情
      /******指派*********/
      assignVisible: false,
      assignForm: {
        employeeId: "" //工程师ID
      },
      assignFormRules: {
        employeeId: [
          {
            required: true,
            message: "请选择工程师",
            trigger: ["blur", "change"]
          }
        ]
      },
      /*****处理工单*******/
      dealVisible: false,
      dealTaskForm: {
        content: "", //内容
        fileIds: [] //文件数组
      },
      dealTaskFormRules: {
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      fileList: []
    };
  },
  mixins: [base],
  components: {
    detailTitleContent,
    allUserSearch,
    uploadOBS
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.synergyTaskId = hasParamsRoute.params.synergyId;
    if (this.synergyTaskId) {
      this.initData();
    }
  },

  computed: {
    btnType() {
      let value = "";
      if (this.detailInfo.taskStatus == 1) {
        value = "yellowBtn";
      }
      if (this.detailInfo.taskStatus == 2) {
        value = "blueBtn";
      }
      if (this.detailInfo.taskStatus == 3 || this.detailInfo.taskStatus == 4) {
        value = "greenBtn";
      }
      return value;
    },

    //操作id
    synergyTaskOperationId() {
      return this.processList.length
        ? this.processList[0].synergyTaskOperationId
        : "";
    }
  },

  methods: {
    //获取工程师所有信息
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          // 获取基本信息
          teamTaskDetail({
            synergyTaskId: this.synergyTaskId
          }),
          //流程信息
          getTeamProcess({
            synergyTaskId: this.synergyTaskId
          })
        ];
        let [detailRes, processRes] = await Promise.all(promiseList);
        //基本信息
        this.detailInfo = this.getFreezeResponse(detailRes, {
          path: "data"
        });

        // 流转图
        this.processList = this.getFreezeResponse(processRes, {
          path: "data.coordinateBaseInfoRespVOS"
        });
        //关联单据
        this.taskBaseInfoRespVO = this.getFreezeResponse(processRes, {
          path: "data.taskBaseInfoRespVO"
        });
        //单据详情
        this.taskBaseInfoDetailRespVOS = this.getFreezeResponse(processRes, {
          path: "data.taskBaseInfoRespVO.taskBaseInfoDetailRespVOS"
        });
        //当前是否可操作
        this.operation = this.getFreezeResponse(processRes, {
          path: "data.operation"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理提示
    hanldeTip(type) {
      let tipTitle, title;
      tipTitle = type == "finish" ? "工单完结" : "催单";
      title = type == "finish" ? "是否确认完结工单？" : "是否确认催单？";
      this.$confirm(tipTitle, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "finish") {
            this.finishTask();
          } else {
            this.reminderOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //完结工单
    async finishTask() {
      try {
        this.loading = true;
        let res = await finishTask({
          synergyTaskOperationId: this.synergyTaskOperationId,
          synergyTaskId: this.synergyTaskId
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.initData();
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/teamWork",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击催单
    async reminderOrder() {
      try {
        let res = await reminders({
          synergyTaskOperationId: this.synergyTaskOperationId
        });
        if (res.code == "OK") {
          this.$message.success("已催单");
          this.initData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击展开收起
    handleFold() {
      this.isFold = !this.isFold;
    },
    handleSelectionChange() {
      console.log(111);
    },

    // 下载附件
    async dowmloadFile(id) {
      try {
        this.loading = true;
        let res = await downFile({ id });
        let url = this.getFreezeResponse(res, {
          path: "data.fileUrl",
          defaultVal: ""
        });
        if (url) {
          exportExcelByUrl(url);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //创建任务单
    createTask() {
      this.routerPush({
        name: "workOrderManage/createTask",
        params: {
          refresh: true
        }
      });
    },
    /******处理工单********/
    //处理工单
    async handleTask() {
      try {
        let res = await handleTask({
          synergyTaskOperationId: this.synergyTaskOperationId,
          ...this.dealTaskForm
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.fileList = [];
          this.handleClose("deal");
          this.initData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********指派弹框***********/
    //点击指派
    handleAssign() {
      this.assignVisible = true;
    },
    // 筛选文件类型和大小
    handleBeforeUpload(file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message.info("上传文件大小不能超过 10MB!");
        return false;
      }
      return true;
    },
    // 上传成功后的回调
    handleSuccess(res, file) {
      let {
        code,
        data: { id }
      } = res;
      if (code === "OK") {
        this.fileList.push({
          ...file,
          fileId: id
        });
      }
    },
    //删除图片
    handleRemove(file) {
      let idx = this.fileList.findIndex(item => {
        return item.uid === file.uid;
      });
      this.fileList.splice(idx, 1);
    },
    //指派工程师
    async assignTask() {
      try {
        let res = await assignTask({
          synergyTaskOperationId: this.synergyTaskOperationId,
          synergyTaskId: this.synergyTaskId,
          ...this.assignForm
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.handleClose("assign");
          this.initData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //打开弹框
    handleOpen(type) {
      if (type == "deal") {
        this.dealVisible = true;
      } else {
        this.assignVisible = true;
      }
    },
    //点击确定
    handleSubmit(type) {
      if (type == "deal") {
        this.$refs.dealTaskForm.validate(valid => {
          if (valid) {
            // 处理工单保存
            this.handleTask();
          }
        });
      } else {
        this.$refs.assignForm.validate(valid => {
          if (valid) {
            // 指派工程师
            this.assignTask();
          }
        });
      }
    },
    //关闭弹框
    handleClose(type) {
      if (type == "deal") {
        this.dealTaskForm = {
          content: "",
          fileIds: []
        };
        this.$refs.dealTaskForm.resetFields();
        this.dealVisible = false;
      } else {
        this.assignForm = {
          employeeId: ""
        };
        this.$refs.assignForm.resetFields();
        this.assignVisible = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/index.scss";
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
}

.baseInfo-content-wrap {
  display: flex;
  // width: 10vw;
  // overflow: hidden;
  // height: 100%;
  .detailInfo-content {
    // width: calc(100% - 300px);
    // height: calc(100% - 55px);
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    // overflow-y: auto;
  }
  .baseInfo-box {
    width: 100%;
    .edit-title {
      width: 100%;
    }
    /deep/.el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin: 0;
    }
    /deep/.el-form-item--mini .el-form-item__label {
      line-height: 40px;
    }
    /deep/.el-form-item--mini .el-form-item__content {
      line-height: 40px;
    }
    .col {
      height: 40px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .team-detail-content {
    padding-left: 20px;
    box-sizing: border-box;
    padding-top: 20px;
    ul {
      margin: 0;
      position: relative;
      &::before {
        content: " ";
        height: 100%;
        width: 1px;
        border-left: 1px dashed #3fc1e1;
        position: absolute;
        top: 0;
        left: 9px;
        display: blockc;
        z-index: 0;
        top: 5px;
      }
      li {
        position: relative;
        overflow: hidden;
        .sort {
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          border-radius: 50%;
          background: #3fc1e1;
          z-index: 1;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          line-height: 20px;
          position: absolute;
          left: 0px;
          top: 5px;
          z-index: 1;
        }
        .detail {
          margin-left: 30px;
          .bills-table {
            width: 800px;
            // float: right;
            // margin-left: 100px;
            background: #f9fafc;
            // padding-bottom: 15px;
            box-sizing: border-box;
            .bills-table-title {
              background: #8dc3ff;
              .el-form {
                overflow: hidden;
                /deep/.el-form-item--mini.el-form-item,
                .el-form-item--small.el-form-item {
                  margin-top: 10px;
                  margin-bottom: 10px;
                }
                /deep/.el-form-item__label {
                  color: #fff;
                }
                /deep/.el-form-item__content {
                  color: #fff;
                }
              }
              position: relative;
              [class^="e6-icon"] {
                font-size: 14px;
                color: #fff;
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1;
              }
            }
            .customer-info {
              background: #e9f3ff;
              overflow: hidden;
              position: relative;
              .detail-button {
                position: absolute;
                top: 0px;
                right: 10px;
                span {
                  color: #3898fc;
                }
                [class^="e6-icon"] {
                  font-size: 14px;
                  color: #3898fc;
                }
              }
            }
            /deep/.el-table {
              margin-top: 20px;
              width: 96%;
              margin-left: 2%;
            }
          }
        }
      }
      .hiddenLine {
        overflow: visible;
      }
    }
    /deep/.el-row::after {
      content: none;
    }
  }
}
/deep/.add-user-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-textarea {
      width: 400px;
    }
  }
}
.upload-demo {
  width: 500px;
}
</style>
